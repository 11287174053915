import { render, staticRenderFns } from "./VGPQuestionnairePanels.vue?vue&type=template&id=3de328be&"
import script from "./VGPQuestionnairePanels.vue?vue&type=script&lang=js&"
export * from "./VGPQuestionnairePanels.vue?vue&type=script&lang=js&"
import style0 from "@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/vgp-questionnaire-panels.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports